<template>
  <div class="home">

    <div class="animationBlock" id="animationBlock">
    </div>

    <div class='login-container'>
      <small class="smallText" v-if="isAuthenticated">Welcome Back!</small>
      <div class='loginButton' @click="login" v-if="!isAuthenticated">Login</div>
      <div class='loginButton' v-if="isAuthenticated">Hello!</div>
      <small class="smallerText" v-if="isAuthenticated">You are logged in</small>
    </div>

  </div>
</template>

<script>

export default {
  data () {
    return {
      isAuthenticated: this.$auth0.isAuthenticated
    }
  },
  created() {
    try {
      if (this.$auth0.isAuthenticated._rawValue.email) {
        this.isAuthenticated = true
      }
    }
    catch(err) {
      this.isAuthenticated = false
    }
    // console.log('value of is authenticated = '+JSON.stringify(this.$auth0.isAuthenticated))
  },
  methods : {
    login() {
      this.$auth0.loginWithRedirect()
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap');

.login-container {
  font-family: 'Roboto Slab', serif;
  float: right;
  margin-right: 100px;
  width: 25%;
  text-align: right;
}

.loginButton {
  font-size: 4em;
  text-decoration: underline;
  cursor:pointer;
}

.smallText {
  font-size: 1em;
}

.smallerText {
  font-size: 0.5em;
}

.animationBlock {
  float:left;
  width:60%;
}
canvas {
  width: 100% !important;
  height: 100% !important;
}

</style>
