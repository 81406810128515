<template>
  <div>

    <el-row class="marginTop row-bg" type="flex" justify="center">
      <el-col :span="18">
        <el-input placeholder="Enter the API key to encrypt" v-model="keyValue">
          <template #prepend>API KEY</template>
        </el-input>
        <el-button v-loading="loadingEncrypt" @click="encryptKey" class="marginTop">Encrypt</el-button>
      </el-col>
    </el-row>

    <el-row type="flex" class="row-bg" justify="center">
      <el-col class="marginTop" :span="18" v-if="encryptedValue">
        <el-input
            type="textarea"
            :rows="auto"
            disabled
            v-model="encryptedValue">
        </el-input>
        <el-button type="primary" @click="copyText" >copy</el-button>
      </el-col>
    </el-row>
  </div>
</template>




<script>
import CryptoJS from 'crypto-js'
import { ElNotification } from 'element-plus'
// import { Share} from '@element-plus/icons-vue'

export default {
  data() {
    return {
      keyValue: '',
      loadingEncrypt: false,
      encryptedValue: '',
      decryptedValue: '',
      bytes: ''
    }
  },
  methods: {
    encryptKey() {
      this.encryptedValue = btoa(CryptoJS.AES.encrypt(this.keyValue, '#*09neCvRCNJcIrVwQ').toString());

    },
    copyText() {
      navigator.clipboard.writeText(this.encryptedValue).then(function() {
        ElNotification.success({
          title: "Copied Text",
          type: "success"
        });

      }, function() {
        ElNotification.error({
          title: "Unable to copy Text",
          type: "error"
        });
      });


    }
  }
}
</script>

<style>
.marginTop {
  margin-top:50px;
}
</style>
