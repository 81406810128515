import { createRouter, createWebHistory } from 'vue-router'
import HomeInfo from './views/HomeInfo.vue'
import ViewAllMessages from './views/ViewAllMessages'
import ViewAllMembers from './views/ViewAllMembers'
import CreateMessage from './views/CreateMessage'
import EncryptKey from './views/EncryptKey'
import EmailEditor from './views/EmailEditor'
import AuthCallback from './views/AuthCallback'
import { authGuard } from '@auth0/auth0-vue';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'HomeInfo',
      component: HomeInfo
    },
    {
      path: '/viewallmessages',
      name: 'ViewAllMessages',
      component: ViewAllMessages,
      beforeEnter: authGuard
    },
    {
      path: '/editor',
      name: 'EmailEditor',
      component: EmailEditor,
      beforeEnter: authGuard
    },
    {
      path: '/viewallmembers',
      name: 'ViewAllMembers',
      component: ViewAllMembers,
      beforeEnter: authGuard
    },
    {
      path: '/createmessage',
      name: 'CreateMessage',
      component: CreateMessage,
      beforeEnter: authGuard
    },
    {
      path: '/encrypt',
      name: 'EncryptKey',
      component: EncryptKey,
      beforeEnter: authGuard
    },
    {
      path: '/callback',
      name: 'AuthCallback',
      component: AuthCallback
    }
  ]
})

export default router
