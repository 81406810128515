<template>
  <div>
    <el-row :span="24"><el-divider border-style="none"></el-divider></el-row>
    <el-row>
      <el-col>
        <div v-if="loading" class="loading"> Loading... </div>
        <div v-if="error" class="error"> {{ error }} </div>
        <el-table
          :data="data"
          ref="filterTable"
          v-loading= "loading">
          <el-table-column
            prop="message1"
            label="Message1"
            >
          </el-table-column>
          <el-table-column
            prop="message2"
            label="Message2"
            >
          </el-table-column>
          <el-table-column
            prop="tags"
            label="Tags"
            :filters="tagFilter"
            :filter-method="filterTag"
            filter-placement="bottom-end"
            width="150px"
            >
            <template #default="scope1">
              <el-tag v-for="tag in scope1.row.tags" :key="tag">{{tag}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="createdby"
            label="Createdby"
            width="200">
          </el-table-column>
          <el-table-column
            fixed="right"
            label="Action"
            width="120">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                @click="dialogFormVisible = true"
                v-on:click="updateMessageData(scope.$index, data, scope.row)">
                Edit
              </el-button>

              <el-button
                v-on:click="deleteMessage(scope.$index, data, scope.row)"
                type="text"
                size="small"
                :loading="deleteMessageloading">
                Remove
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <!-- Modal for editing message -->
    <el-dialog :title="'Edit Message ('+editMessageData.messageid+')'" v-model="dialogFormVisible">
      <el-form :model="editMessageData" @submit.prevent="handleInputConfirm">
        <el-form-item label="Message 1" :label-width="formLabelWidth">
          <el-input v-model="editMessageData.message1" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="Message 2" :label-width="formLabelWidth">
          <el-input v-model="editMessageData.message2" type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <el-tag
            v-for="edtag in editMessageData.tags"
            :key="edtag"
            closable
            :disable-transitions="false"
            @close="handleClose(edtag)">
            {{edtag}}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="dynamicTagsInputVisible"
            v-model="dynamicTagsInputValue"
            ref="saveTagInput"
            size="mini"
            @keyup.enter="handleInputConfirm"
            @blur="handleInputConfirm"
          >
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button v-on:click="closeDialog()">Cancel</el-button>
          <el-button type="primary" v-on:click="editMessage()" :loading="loadingSaveMessage">Save</el-button>
        </span>
      </template>

    </el-dialog>

</div>


</template>

<script>
import { ElNotification } from 'element-plus'
import axios from 'axios';
import appConfig from '../../app_config.json';

export default {
  data () {
    return {
      loading: true,
      post: null,
      error: null,
      data: [],
      backupData: [],
      tableData: [],
      deleteMessageloading:false,
      dialogFormVisible: false,
      counter:0,
      tagFilter: [{ text: 'EN', value: 'EN' }],
      editMessageData: {
          message1: '',
          message2: '',
          messageid: '',
          index:'',
          tags: []
        },
      dynamicTagsInputVisible: false,
      dynamicTagsInputValue: '',
      formLabelWidth: '120px',
      loadingSaveMessage:false
    }
  },
  async created () {
    try {
      // const token = await this.$auth0.getAccessTokenSilently()

      let axiosConfig = {
        headers: {
          'Authorization': `Bearer ${this.$auth0.idTokenClaims._value.__raw}`,
          'token': this.$auth0.idTokenClaims._value.__raw
        }
      }

      let response = await axios.get(appConfig.backendDomain+'/messages/all/', axiosConfig)
      this.data = response.data;
      let allTags = Array.from(response.data.map(data => data.tags));
      let allTagsArray = [];
      allTags.forEach( (el)=> {
        allTagsArray.push(el[0]);
      })

      let counts = {};
      for (let i = 0; i < allTagsArray.length; i++) {
        let num = allTagsArray[i];
        counts[num] = counts[num] ? counts[num] + 1 : 1;
      }

      let tagFilter = [];

      Object.keys(counts).forEach( (el) => {
        tagFilter.push({
          text: el+" ("+counts[el]+")",
          value: el
        })
      });
      this.tagFilter = tagFilter;
      this.loading = false;
    }
    catch (err) {
      console.log(err)
    }

  },
  methods: {
    formatter(row) {
      return row.address;
    },
    filterTag(value, row) {
      return (row.tags.indexOf(value) > -1);
    },
    closeDialog: function() {
      this.loadingSaveMessage = false;
      this.dialogFormVisible = false;
      this.counter= 0;
    },
    handleClose(tag) {
      this.editMessageData.tags.splice(this.editMessageData.tags.indexOf(tag), 1);
    },
    showInput() {
      this.dynamicTagsInputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.dynamicTagsInputValue;
      if (inputValue) {
        this.editMessageData.tags.push(inputValue);
      }
      this.counter--;
      this.dynamicTagsInputVisible = false;
      this.dynamicTagsInputValue = '';
    },
    async refreshMessages() {
      try {
        // const token = await this.$auth0.getAccessTokenSilently()

        let axiosConfig = {
          headers: {
            'Authorization': `Bearer ${this.$auth0.idTokenClaims._value.__raw}`,
            'token': this.$auth0.idTokenClaims._value.__raw
          }
        }

        let response = await axios.get(appConfig.backendDomain+'/messages/all/', axiosConfig)

        this.data = response.data;
        let allTags = Array.from(response.data.map(data => data.tags));
        let allTagsArray = [];
        allTags.forEach( (el)=> {
          allTagsArray.push(el[0]);
        })

              let counts = {};
              for (let i = 0; i < allTagsArray.length; i++) {
                var num = allTagsArray[i];
                counts[num] = counts[num] ? counts[num] + 1 : 1;
              }

              let tagFilter = [];

              Object.keys(counts).forEach( (el) => {
                tagFilter.push({
                  text: el+" ("+counts[el]+")",
                  value: el
                })
              });
              this.tagFilter = tagFilter;
              this.loading = false;
      }
      catch(err) {
        console.log(err)
      }


    },
    async deleteMessage(index, data, row) {
      try {
        // const token = await this.$auth0.getAccessTokenSilently()

        let self = this;
        self.loading = true;

        let axiosConfig = {
          headers: {
            'Authorization': `Bearer ${this.$auth0.idTokenClaims._value.__raw}`,
            'token': this.$auth0.idTokenClaims._value.__raw
          }
        };

        let response = await axios.get(appConfig.backendDomain+'/messages/removemessage/'+row._id, axiosConfig)


              if(response.data == "Successfully deleted") {
                data.splice(data.splice(data.findIndex( element => {return element._id == row._id}), 1), 1);

                ElNotification.success({
                  title: "Message deleted",
                  type: "success"});
                self.loading = false;
                self.refreshMessages();
              }
              else {
                ElNotification.error({
                  title: "Unable to delete message",
                  type: "error"});
                self.loading = false;
              }

      }
      catch (err) {
        console.log(err)
      }
    },
    updateMessageData: function(index, data, row) {
        this.editMessageData.message1 = row.message1;
        this.editMessageData.message2 = row.message2;
        this.editMessageData.messageid = row._id;
        this.editMessageData.tags = row.tags;
        this.editMessageData.index = index;
    },
    async editMessage() {
      try {
        // const token = await this.$auth0.getAccessTokenSilently()

        let self = this;
        self.loadingSaveMessage=true;
        let axiosConfig = {
          headers: {
            'Authorization': `Bearer ${this.$auth0.idTokenClaims._value.__raw}`,
            'token': this.$auth0.idTokenClaims._value.__raw
          }
        }

        let response = await axios.post(appConfig.backendDomain+'/messages/editmessage/'+self.editMessageData.messageid, self.editMessageData, axiosConfig)
              if(response.data.status == "Successfully edited data") {
                self.data[self.editMessageData.index].message1 = response.data.data.message1;
                self.data[self.editMessageData.index].message2 = response.data.data.message2;
                self.data[self.editMessageData.index].tags = response.data.data.tags;
                self.counter= 0;

                ElNotification.success({
                  title: "Message edited",
                  type: "success"});
                self.loadingSaveMessage = false;
                self.dialogFormVisible = false;
                self.refreshMessages();
              }
              else {
                ElNotification.error({
                  title: "Unable to edit message",
                  // message: data[index]._id,
                  type: "error"});
                self.loadingSaveMessage = false;
              }
      }
      catch (err) {
        console.log(err)
      }


      },
  }
}
</script>
