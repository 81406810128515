<template>
    <div>
      <el-row :span="24"><el-divider border-style="none"></el-divider></el-row>
      <el-row>
        <el-col>
          <div v-if="loading" class="loading"> Loading... </div>
          <div v-if="error" class="error"> {{ error }} </div>
          <el-table
            :data="data"
            ref="filterTable"
            v-loading= "loading">
            <el-table-column
              prop="createdAt"
              label="Created At"
              >
            </el-table-column>
            <el-table-column
              prop="name"
              label="Name"
              >
            </el-table-column>
            <el-table-column
              prop="email"
              label="Email"
              >
            </el-table-column>
            <el-table-column
              prop="domain"
              label="Domain"
              >
            </el-table-column>

          </el-table>
        </el-col>
      </el-row>
  
  </div>
  
  
  </template>
  
  <script>
  import axios from 'axios';
  import appConfig from '../../app_config.json';
  
  export default {
    data () {
      return {
        loading: true,
        post: null,
        error: null,
        data: [],
        backupData: [],
        tableData: [],
        deleteMessageloading:false,
        dialogFormVisible: false,
        counter:0,
        dynamicTagsInputVisible: false,
        dynamicTagsInputValue: '',
        formLabelWidth: '120px',
        loadingSaveMessage:false
      }
    },
    async created () {
      try {
        // const token = await this.$auth0.getAccessTokenSilently()
  
        let axiosConfig = {
          headers: {
            'Authorization': `Bearer ${this.$auth0.idTokenClaims._value.__raw}`,
            'token': this.$auth0.idTokenClaims._value.__raw
          }
        }
  
        let response = await axios.get(appConfig.backendDomain+'/member/all/', axiosConfig)
        this.data = response.data;
        
        this.loading = false;
      }
      catch (err) {
        console.log(err)
      }
  
    },
    methods: {
      formatter(row) {
        return row.address;
      },
      async refreshMessages() {
        try {
          // const token = await this.$auth0.getAccessTokenSilently()
  
          let axiosConfig = {
            headers: {
              'Authorization': `Bearer ${this.$auth0.idTokenClaims._value.__raw}`,
              'token': this.$auth0.idTokenClaims._value.__raw
            }
          }
  
          let response = await axios.get(appConfig.backendDomain+'/member/all/', axiosConfig)
  
          this.data = response.data;
          
        }
        catch(err) {
          console.log(err)
        }
  
  
      },
    }
  }
  </script>
  