<template>
  <div id="app">
    <div id="nav">
      <el-menu
          :default-active="activeIndex"
          :router="true"
          class="el-menu-demo"
          mode="horizontal"
      >
        <el-menu-item index="/"><img src="https://user-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_1440,w_720,f_auto,q_auto/452503/dj9u7tws3ugmhkwcsd3i.png" style="width: 30px;">` Lal` </el-menu-item>
        <el-menu-item index="/viewallmessages" v-if="isAuthenticated">All Messages</el-menu-item>
        <el-menu-item index="/createmessage" v-if="isAuthenticated">Create Message</el-menu-item>
        <el-menu-item index="/encrypt" v-if="isAuthenticated">Rate Shop Widget</el-menu-item>
        <el-menu-item index="/viewallmembers" v-if="isAuthenticated">Member Discount Widget</el-menu-item>
        <el-menu-item index="/editor" v-if="isAuthenticated">Mail Editor</el-menu-item>
        <el-menu-item index="/logout" v-on:click="logout" v-if="isAuthenticated">Logout</el-menu-item>
      </el-menu>

    </div>
    <div id="componentArea">
      <router-view/>
    </div>

    <el-footer>
      <div id="copyright">
        <div class="copycontainer">
          <div class="sonicerik">
            <div class="copytext">Copyrights ©2019 All Rights Reserved by Hotel Linkage Inc. Hotel Online Group Company.</div>
            <div class="sosyal">
              <div class="sosyalicons">
                <ul>
                  <li><a href="https://www.facebook.com/pages/Hotel-Linkage/368764373272007" target="_blank" title="Facebook"><i class="fa fa-facebook"></i></a></li>
                  <li><a href="https://www.linkedin.com/company/hotel-linkage" target="_blank" title="LinkedIn"><i class="fa fa-linkedin"></i></a></li>
                  <li><a href="https://twitter.com/Hotellinkage" target="_blank" title="Twitter"><i class="fa fa-twitter"></i></a></li>
                  <li><a href="https://plus.google.com/+Hotellinkage1/" target="_blank" title="Twitter"><i class="fa fa-google-plus"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-footer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeIndex: '1',
      storedData: localStorage.getItem('email'),
      isAuthenticated: this.$auth0.isAuthenticated
    }
  },
  created() {
    try {
      if (this.$auth0.isAuthenticated._rawValue.email) {
        this.isAuthenticated = true
      }
    }
    catch(err) {
      this.isAuthenticated = false
    }
  },
  watch: {
    $route (to) {
      this.activeIndex = to.path;
    }
  },
  mounted() {
    this.activeIndex = this.$route.path;

  },
  methods: {
    logout() {
      this.$auth0.logout({ returnTo: window.location.origin });
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 0 0 0 30px;
}
#componentArea {
  padding:0 20px 0 70px;
  margin-bottom: 80px;
}
.el-footer {
  width:100%;
  position: fixed;
  left:0;
  bottom: 0;
  z-index: 4;
  padding:0 !important;
}
#copyright {
  background-color: #1b1b1b;
  color: #888;
  color: #aaa;
  padding: 10px 0 13px 0;
  font-family: 'Source Sans Pro', sans-serif;
}
.copycontainer {
  position: relative;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0;
}
.sonicerik {
  /* max-width: 1160px; */
  padding: 15px 0 15px 0;
}
#copyright .sosyal {
  float: right;
  margin: 0;
  padding: 0;
}
#copyright .sosyalicons {
  float: right;
  margin: -30px 0;
}
#copyright .sosyalicons li {
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  float: left;
}
#copyright a {
  color: #999;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}

</style>
