<template>
  <div>
    <el-row :span="24"><el-divider border-style="none"></el-divider></el-row>
    <el-row type="flex" class="row-bg" justify="center">
      <el-col :span="8"></el-col>
      <el-col :span="8">
        <el-form :inline="false" :model="messages"  @submit.prevent="handleInputConfirm">
          <el-form-item label="Message 1">
            <el-input v-model="messages.message1" placeholder="Message 1" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="Message 2">
            <el-input v-model="messages.message2" placeholder="Message 2" type="textarea"></el-input>
          </el-form-item>
          <el-form-item>
            <el-tag
                :key="tag"
                v-for="tag in dynamicTags"
                closable
                :disable-transitions="false"
                @close="handleClose(tag)">
              {{tag}}
            </el-tag>
            <el-input
                class="input-new-tag"
                v-if="dynamicTagsInputVisible"
                v-model="dynamicTagsInputValue"
                ref="saveTagInput"
                size="mini"
                @keyup.enter="handleInputConfirm"
                @blur="handleInputConfirm"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmit" :loading="loading">Save</el-button>
            <el-progress :show-text="showText" :stroke-width="5" :percentage="percentage" v-bind="props"></el-progress>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
  </div>

</template>




<script>
  import { ElNotification } from 'element-plus'
  import axios from 'axios';
  import appConfig from '../../app_config.json';

  export default {
    data() {
      return {
        messages: {
          message1: '',
          message2: ''
        },
        createdby: this.$auth0.idTokenClaims._value.email,
        loading:false,
        percentage: 0,
        status:"",
        showText:false,
        props: {},
        dynamicTags: [],
        dynamicTagsInputVisible: false,
        dynamicTagsInputValue: ''
      }
    },
    methods: {
      handleClose(tag) {
        this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      },
      showInput() {
        this.dynamicTagsInputVisible = true;
        this.$nextTick(() => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },
      handleInputConfirm() {
        let inputValue = this.dynamicTagsInputValue;
        if (inputValue) {
          this.dynamicTags.push(inputValue);
        }
        this.dynamicTagsInputVisible = false;
        this.dynamicTagsInputValue = '';
      },
      async onSubmit() {
        var self = this;
        this.loading = true;
        var messagedata = {
          "message1": this.messages.message1,
          "message2": this.messages.message2,
          "createdby": this.createdby,
          "createdat": Date(),
          "updatedat": Date(),
          "tags": this.dynamicTags
        };
        this.percentage = 50;

        let axiosConfig = {
          headers: {
              'Authorization': `Bearer ${this.$auth0.idTokenClaims._value.__raw}`,
              'token': this.$auth0.idTokenClaims._value.__raw
          }
        };
        try {
          let response = await axios.post(appConfig.backendDomain+'/messages/addMessage', messagedata, axiosConfig)

                if(response.data == "Successfully Inserted Data") {
                  ElNotification.success({
                    title: "Message added",
                    message: messagedata.message1+" "+messagedata.message2,
                    type: "success"});

                  self.messages.message1 = "";
                  self.messages.message2 = "";
                  self.loading = false;
                  self.percentage = 100;
                  self.props = {status:"success"};
                  setTimeout(function(){
                    self.props = {};
                    self.percentage = 0;
                  },1000);
                }
                else {
                  ElNotification.error({
                    title: "Unable to add message",
                    message: messagedata.message1+" "+messagedata.message2,
                    type: "error"});

                  self.loading = false;
                  self.percentage = 100;
                  self.percentage = 0;
                  self.props = {status:"exception"};
                  setTimeout(function(){
                    self.props = {};
                    self.percentage = 0;
                  },1000);
                }
        }
        catch (err) {
          console.log(err)
        }
      }
    }
  }
</script>

<style>
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px !important;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px !important;
    margin-left: 10px;
    vertical-align: bottom;
  }
</style>
