import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)

import router from './router'
app.use(router)

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
app.use(ElementPlus)

import { createAuth0 } from '@auth0/auth0-vue'
import authConfig from '../auth_config.json'

app.use(
    createAuth0({
        domain: authConfig.domain,
        client_id: authConfig.clientId,
        redirect_uri: `${window.location.origin}/callback`,
        responseType: 'token id_token',
        scope: 'openid profile email jwt'
    })
);

app.mount('#app')
